<template>
  <Layout id="layouttttttttttt">
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body" >
            <h1><i class="mdi mdi-credit-card-minus"
                                        style="transform: scale(1.7);"></i> Manage your purchases</h1>
            <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to add purchases records via scanned image or via form. </label>
            <div class="accueil" v-if="ImportInvoice ">
              <div style="text-align: left;padding: 2rem;">
                <button @click="ImportInvoice = false " type="button" class="btn btn-outline btn-info mb-3"><span
                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline" style="transform: scale(1.7);"></i>
                  </span>Go back </button>
              </div>
              <div class="form-container">
                <h2 style="margin-bottom: 20px">Add invoice</h2>
                <span> </span>

                <div>
                  <button class="file-upload-button" @click="selectAndUploadFile">
                    Import
                  </button>

                  <div v-if="uploadedFileUrl">
                    <div style="display: flex; justify-content: space-around">
                      <div class="uploaded-file">
                        <img v-if="isImage" id="imgOCR" :src="uploadedFileUrl" alt="Uploaded Image"
                          class="uploaded-image" />
                        <div v-for="(word, index) in recognizedWords" :key="`word_${index}`" class="word"
                          style="position: absolute;"
                          :style="{ left: word.style.left + '!important', top: word.style.top + '!important' }">
                          {{ word.text }}
                        </div>
                        <embed v-if="!isImage" :src="uploadedFileUrl" type="application/pdf" width="100%" height="500px"
                          class="uploaded-pdf" />
                      </div>

                      <div style="width: 30%" v-if="uploadedFileUrl" class="uploaded-file">
                        <div class="word-container">
                          <span v-for="(Splitted_Words_from_text_word, index) in Splitted_Words_from_text"
                            :key="`Splitted_Words_${index}`" class="draggable-word" :draggable="true"
                            @dragstart="onDragStart(index, $event)">
                            {{ Splitted_Words_from_text_word }}
                          </span>

                          <div>
                            <button style="margin-top: 200px" class="file-download-button" @click="EnregistrerProduit">
                              Enregistrer
                            </button>
                          </div>
                        </div>
                      </div>

                      <div style="display: flex; flex-direction: column;">

                        <p class="mb-1 font-weight-bold">informations facture</p>

                        <table class="TableInfo" border="1">
                          <thead>
                            <tr>
                              <th style="width: 175px">Information</th>
                              <th>Valeur</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="custom-height">
                              <td>- Facture N°</td>
                              <td :draggable="true" @drop="onDrop('Invoice Number', $event)"
                                @dragstart="onDragStartCell('Invoice Number', $event)" @dragend="onDragEnd"
                                @dragover="onDragOver" :style="cellStyles['Invoice Number']"
                                @dblclick="startEditing('Invoice Number')">
                                <span v-if="!editing['Invoice Number']">{{
                                  InvoiceVariables["Invoice Number"]
                                }}</span>
                                <input v-else type="text" v-model="InvoiceVariables['Invoice Number']"
                                  @blur="stopEditing('Invoice Number')" @keyup.enter="stopEditing('Invoice Number')" />
                              </td>
                            </tr>

                            <tr class="custom-height">
                              <td>- Supplier Name</td>
                              <td :draggable="true" @drop="onDrop('Supplier Name', $event)"
                                @dragstart="onDragStartCell('Supplier Name', $event)" @dragend="onDragEnd"
                                @dragover="onDragOver" :style="cellStyles['Supplier Name']"
                                @dblclick="startEditing('Supplier Name')">
                                <span v-if="!editing['Supplier Name']">{{
                                  InvoiceVariables['Supplier Name']
                                }}</span>
                                <input v-else type="text" v-model="InvoiceVariables['Supplier Name']"
                                  @blur="stopEditing('Supplier Name')" @keyup.enter="stopEditing('Supplier Name')" />
                              </td>
                            </tr>

                            <tr class="custom-height">
                              <td>- Total TTC</td>
                              <td :draggable="true" @dragstart="
                                onDragStartCell('Total all-inclusive', $event)
                                " @drop="onDrop('Total all-inclusive', $event)" @dragend="onDragEnd"
                                @dragover="onDragOver" :style="cellStyles['Total all-inclusive']"
                                @dblclick="startEditing('Total all-inclusive')">
                                <span v-if="!editing['Total all-inclusive']">{{
                                  InvoiceVariables['Total all-inclusive']
                                }}</span>
                                <input v-else type="text" v-model="InvoiceVariables['Total all-inclusive']"
                                  @blur="stopEditing('Total all-inclusive')"
                                  @keyup.enter="stopEditing('Total all-inclusive')" />
                              </td>
                            </tr>

                            <tr class="custom-height">
                              <td>- Currency</td>
                              <td :draggable="true" @dragstart="
                                onDragStartCell('Currency', $event)
                                " @drop="onDrop('Currency', $event)" @dragend="onDragEnd" @dragover="onDragOver"
                                :style="cellStyles.Currency" @dblclick="startEditing('Currency')">
                                <span v-if="!editing.Currency">{{
                                  InvoiceVariables.Currency
                                }}</span>
                                <input v-else type="text" v-model="InvoiceVariables.Currency"
                                  @blur="stopEditing('Currency')" @keyup.enter="stopEditing('Currency')" />
                              </td>
                            </tr>

                            <tr class="custom-height">
                              <td>- Date de facture</td>
                              <td :draggable="true" @dragstart="
                                onDragStartCell('Invoice Date', $event)
                                " @drop="onDrop('Invoice Date', $event)" @dragend="onDragEnd" @dragover="onDragOver"
                                :style="cellStyles['Invoice Date']" @dblclick="startEditing('Invoice Date')">
                                <span v-if="!editing['Invoice Date']">{{
                                  InvoiceVariables['Invoice Date']
                                }}</span>
                                <input v-else type="text" v-model="InvoiceVariables['Invoice Date']"
                                  @blur="stopEditing('Invoice Date')" @keyup.enter="stopEditing('Invoice Date')" />
                                  <input v-else type="text" v-model="InvoiceVariables['Due Date']"
                                  @blur="stopEditing('Due Date')" @keyup.enter="stopEditing('Due Date')" />
                              </td>
                            </tr>

                            <!-- Rest of your table rows -->
                          </tbody>
                        </table>
                        <!-- 
                        <p class="mb-1 mt-3 font-weight-bold">Données de(s) produit(s)</p>

                        <table class="TableInfo" border="1">
                          <thead>
                            <tr>
                              <th>Produit</th>
                              <th>Désignation</th>
                              <th>Quantité</th>
                              <th>Total HT</th>
                              <th>TVA</th>
                              <th>Total TTC</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(prod,index) in InvoiceVariables.products" :key="`product_${index}`" class="custom-height">
                              <td :ref="`produit_${index}`" :draggable="true" @drop="onDropProduct(index, `produit_${index}`, $event)"
                                @dragstart="onDragStartCellProd(index, `produit`, $event)" @dragend="onDragEnd"
                                @dragover="onDragOver"
                                @dblclick="startEditingProd(index,'produit')">
                                <span v-if="!prod.produit.editing">{{
                                  prod.produit.value
                                }}</span>
                                <input v-else type="text" v-model="prod.produit.value"
                                  @blur="stopEditingProd(index,'produit')" @keyup.enter="stopEditingProd(index,'produit')" /></td>
                              <td :ref="`designation_${index}`" :draggable="true" @drop="onDropProduct(index, `designation_${index}`, $event)"
                                @dragstart="onDragStartCellProd(index,`designation`, $event)" @dragend="onDragEnd"
                                @dragover="onDragOver"
                                @dblclick="startEditingProd(index,'designation')">
                                <span v-if="!prod.designation.editing">{{
                                  prod.designation.value
                                }}</span>
                                <input v-else type="text" v-model="prod.designation.value"
                                  @blur="stopEditingProd(index,'designation')" @keyup.enter="stopEditingProd(index,'designation')" />
                              </td>
                              <td :ref="`quantity_${index}`" :draggable="true" @drop="onDropProduct(index, `quantity_${index}`, $event)"
                                @dragstart="onDragStartCellProd(index,`quantity`, $event)" @dragend="onDragEnd"
                                @dragover="onDragOver"
                                @dblclick="startEditingProd(index,'quantity')">
                                <span v-if="!prod.quantity.editing">{{
                                  prod.quantity.value
                                }}</span>
                                <input v-else type="text" v-model="prod.quantity.value"
                                  @blur="stopEditingProd(index,'quantity')" @keyup.enter="stopEditingProd(index,'quantity')" />
                              </td>
                              <td :ref="`totalHT_${index}`" :draggable="true" @drop="onDropProduct(index, `totalHT_${index}`, $event)"
                                @dragstart="onDragStartCellProd(index,`totalHT`, $event)" @dragend="onDragEnd"
                                @dragover="onDragOver"
                                @dblclick="startEditingProd(index,'totalHT')">
                                <span v-if="!prod.totalHT.editing">{{
                                  prod.totalHT.value
                                }}</span>
                                <input v-else type="text" v-model="prod.totalHT.value"
                                  @blur="stopEditingProd(index,'totalHT')" @keyup.enter="stopEditingProd(index,'totalHT')" />
                              </td>
                              <td :ref="`tva_${index}`" :draggable="true" @drop="onDropProduct(index, `tva_${index}`, $event)"
                                @dragstart="onDragStartCellProd(index,`tva`, $event)" @dragend="onDragEnd"
                                @dragover="onDragOver"
                                @dblclick="startEditingProd(index,'tva')">
                                <span v-if="!prod.tva.editing">{{
                                  prod.tva.value
                                }}</span>
                                <input v-else type="text" v-model="prod.tva.value"
                                  @blur="stopEditingProd(index,'tva')" @keyup.enter="stopEditingProd(index,'tva')" />
                              </td>
                              <td :ref="`totalTTC_${index}`" :draggable="true" @drop="onDropProduct(index, `totalTTC_${index}`, $event)"
                                @dragstart="onDragStartCellProd(index,`totalTTC`, $event)" @dragend="onDragEnd"
                                @dragover="onDragOver"
                                @dblclick="startEditingProd(index,'Total all-inclusive')">
                                <span v-if="!prod['Total all-inclusive'].editing">{{
                                  prod['Total all-inclusive'].value
                                }}</span>
                                <input v-else type="text" v-model="prod['Total all-inclusive'].value"
                                  @blur="stopEditingProd(index,'Total all-inclusive')" @keyup.enter="stopEditingProd(index,'Total all-inclusive')" />
                              </td>
                            </tr>
                            </tbody>
                            </table>
                            <div class="right-0">
                              <button type="button" class="btn btn-success mt-1" style="width: 3rem;float: right;" @click="initProdObj()"><i class="mdi mdi-plus"></i></button>
                            </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Loading spinner -->
                <div v-if="isLoading" class="loading-spinner">
                  <div class="spinner"></div>
                  <p>Loading...</p>
                </div>
              </div>
            </div>
            <div v-else-if="AddingInvoice">
                            <button @click="AddingInvoice = false;" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <form @submit.prevent="preventsubmit">
                                <div class="formulaire" >
                                  <div style="margin-left: auto; margin-right: auto; width: 50%;">
                                    <b-form-group label="Invoice Date" label-for="invoiceDate">
                                        <b-form-input  v-model="invoiceDate" type="date" id="invoiceDate" >
                                          
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Due Date" label-for="dueDate">
                                        <b-form-input  v-model="dueDate" type="date" id="dueDate" >
                                          
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Invoice Number" label-for="InvoiceNum">
                                        <b-form-input  v-model="InvoiceNum" type="text" id="InvoiceNum" 
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Supplier Name" label-for="SuppName">
                                      <multiselect id="SuppName"  v-model="Supplier" :options="suppliers"></multiselect>
                                    </b-form-group>
                                    
                                    <b-form-group label="Total all-inclusive" label-for="total">
                                        <b-form-input  v-model="total" type="text" id="total" 
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Currency" label-for="Currency">
                                      <multiselect id="Currency"  v-model="currency" :options="currencies"></multiselect>
                                    </b-form-group>
                                    <div class="mt-5 mb-5 text-center">
                                    <button type="submit" 
                                        class="btn w-25 btn-outline-primary btn-bordered-primary" @click="ajouterFacture()">Add</button>
                                </div>
                              </div>
                                </div>
                                
                                
                            </form>
            </div>

            <div v-else>
              <p class="text-muted font-13 mb-4"></p>
              <div class="row mb-md-4">
                <div class="col-sm-12 col-md-4">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 align-items-center" style="display: flex;  align-items: flex-start;">
                  <label class="d-inline-flex align-items-center">Add new purchase invoice</label>
                  <button  type="button" class="mb-1 btn btn-primary ml-2 " @click="ImportInvoice = true">
                                        <i class="mdi mdi-file-image"
                                        style="transform: scale(1.7);"></i>
                                      </button>
                  <button type="button" class=" mb-1 btn btn-primary ml-2 " @click="AddingInvoice = true">
                                        <i class="mdi mdi-format-align-left"
                                        style="transform: scale(1.7);"></i></button>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-4">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input v-model="filter" type="search" placeholder="Search..."
                        class="form-control form-control-sm ml-2"></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive table-striped mb-0">
                <b-table style="padding: .5rem;" :items="listeFacture" :fields="fields" responsive="sm"
                  :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                  :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"></b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
            <!-- end card-box -->
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </Layout>
</template>

<script>
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from "vue-multiselect";
import router from "../router";
/**
 * Invoice component
 */
export default {
  page: {
    title: "Invoice",
  },
  data() {
    return {
      invoiceDate: "",
      dueDate: "",
      InvoiceNum: "",
      currencies: ['TND', 'USD', 'EUR', 'GBP'],
      currency: "",
      total: "",
      suppliers: [],
      Supplier: "",
      listeFacture: [],
      ImportInvoice: false,
      AddingInvoice: false,
      recognizedWords: {},
      TextfromImage: [],

      isOpen: false,
      Splitted_Words_from_text: [],

      words: ["Word2", "Word3", "Word4", "Word5", "Word6", "Word7", "Word8"],

      InvoiceVariables: {
        "Societe":localStorage.getItem('societe').slice(1, -1),
        "Invoice Number": "",
        "Supplier Name": "",
        "Invoice Date": "",
        "Due Date": "",
        "Total all-inclusive": "",
        Currency: ""
      },
      editing: {
        "Invoice Number": false,
        "Supplier Name": false,
        "Invoice Date": false,
        "Due Date": false,
        "Total all-inclusive": false,
        Currency: false
      },
      cellStyles: {
        "Invoice Number": {
          backgroundColor: "",
        },

        "Supplier Name": {
          backgroundColor: "",
        },

        "Invoice Date": {
          backgroundColor: "",
        },

        "Due Date": {
          backgroundColor: "",
        },
        "Total all-inclusive": {
          backgroundColor: "",
        },

        Currency: {
          backgroundColor: "",
        },
      },

      mobileBreakpoint: 768,
      isSidebarOpen: false,
      listeStock: [],
      idSoc: "S123",
      uploadedFile: null,
      uploadedFileUrl: null,
      isImage: false,
      isLoading: false,
      imageUrl: "https://finex.4help.tn/",


      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        {
          key: "Invoice Date",
          sortable: true,
        },
        {
          key: "Due Date",
          sortable: true,
        },
        {
          key: "Invoice Number",
          sortable: true,
        },
        {
          key: "Supplier Name",
          sortable: true,
        },
        {
          key: "Total all-inclusive",
          sortable: true,
        },
        {
          key: "Currency",
          sortable: true,
        }
      ],
    };
  },
  components: {
    Layout,
    Multiselect,
  },
  async mounted() {
   axios.get(`https://finex.4help.tn/api/v1/getSuppliers/${localStorage.getItem('societe').slice(1, -1)}`).then(res => { this.suppliers=  res.data.map(supplier => supplier["Supplier Name"]); })
    await this.getFactureAchat()
  },
  created() {
  },
  computed: {
    sidebarState() {
      return this.isSidebarOpen ? "open" : "closed";
    },
    rows() {
      return this.listeFacture.length;
    },
  },

  methods: {
    ajouterFacture() {
      this.InvoiceVariables["Societe"]= localStorage.getItem('societe').slice(1, -1);
      this.InvoiceVariables["Supplier Name"] = this.Supplier;
      this.InvoiceVariables["Invoice Date"] = this.invoiceDate;
      this.InvoiceVariables["Due Date"] = this.dueDate;
      this.InvoiceVariables["Currency"] = this.currency;
      this.InvoiceVariables["Invoice Number"] = this.InvoiceNum;
      this.InvoiceVariables["Total all-inclusive"] = this.total;
      axios.post("https://finex.4help.tn/api/v1/addpruchaseinvoice", this.InvoiceVariables).then(res => {
        if (res.status === 200) {
          this.AddingInvoice = false
          this.getFactureAchat()
          swal({
            title: "Success",
            text: "invoice added!",
            icon: "success",
          })
          this.router.push("/purchases")
        } else {
          console.log("Error")
          swal({
            title: "Error",
            text: "invoice not added!",
            icon: "error",
          })
        }
      
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getFactureAchat() {
      this.listeFacture = await axios.get(`https://finex.4help.tn/api/v1/getpurchaseinvoice/${localStorage.getItem('societe').slice(1, -1)}`).then(res => { return res.data })
      console.log(this.listeFacture)
    },
    initProdObj() {
      this.InvoiceVariables.products.push({
        Societe: localStorage.getItem('societe').slice(1, -1),
        produit: { value: "", editing: false },
        designation: { value: "", editing: false },
        quantity: { value: "", editing: false },
        totalHT: { value: "", editing: false },
        tva: { value: "", editing: false },
        totalTTC: { value: "", editing: false }
      })
    },

    showPopup() {
      this.isPopupVisible = true;
    },
    hidePopup() {
      this.isPopupVisible = false;
    },



    startEditing(cellKey) {
      this.editing[cellKey] = true;
    },
    stopEditing(cellKey) {
      this.editing[cellKey] = false;
    },
    startEditingProd(cellKey, field) {
      this.InvoiceVariables.products[cellKey][field].editing = true;
    },
    stopEditingProd(cellKey, field) {
      this.InvoiceVariables.products[cellKey][field].editing = false;
    },
    onDrop(dataKey, event) {
      event.preventDefault();
      const word = event.dataTransfer.getData("text/plain");
      this.InvoiceVariables[dataKey] = word;
      this.cellStyles[dataKey].backgroundColor = "#ff4e00";
      setTimeout(() => {
        this.cellStyles[dataKey].backgroundColor = "transparent";
      }, 500);
    },

    onDropProduct(dataKey, Elem, event) {
      event.preventDefault();
      const word = event.dataTransfer.getData("text/plain");
      const field = (Elem.split('_'))[0]
      this.InvoiceVariables.products[dataKey][field].value = word;
      this.$refs[Elem][0].style.backgroundColor = "#ff4e00";
      setTimeout(() => {
        this.$refs[Elem][0].style.backgroundColor = "transparent";
      }, 500);
    },

    onDragStartCell(cellKey, event) {
      //alert(this.InvoiceVariables[cellKey]);
      event.dataTransfer.setData("text/plain", this.InvoiceVariables[cellKey]);
      this.InvoiceVariables[cellKey] = "";
    },

    onDragStartCellProd(cellKey, Elem, event) {
      //alert(this.InvoiceVariables[cellKey]);
      event.dataTransfer.setData("text/plain", this.InvoiceVariables.products[cellKey][Elem].value);
      this.InvoiceVariables.products[cellKey][Elem].value = "";
    },

    onDragStart(index, event) {
      event.dataTransfer.setData(
        "text/plain",
        this.Splitted_Words_from_text[index]
      );
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDragEnd() { },

    initInvoiceVars() {
      this.InvoiceVariables = {
        Societe: localStorage.getItem('societe').slice(1, -1),
        "Invoice Number": "",
        "Supplier Name": "",
        "Invoice Date": "",
        "Due Date": "",
        "Total all-inclusive": "",
        Currency: "",
      };
      this.uploadedFile = null;
      this.uploadedFileUrl = null;
      this.isImage = false;
      this.isLoading = false;
    },

    selectAndUploadFile() {

      this.initInvoiceVars()

      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".pdf, .jpg, .jpeg, .png";
      input.style.display = "none";

      input.addEventListener("change", (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          this.uploadedFile = selectedFile;
          this.isImage = this.uploadedFile.type.includes("image");
          this.uploadFile();
        }
      });

      input.click();
    },

    async uploadFile() {
      try {
        const formData = new FormData();
        formData.append("Article_OCR_Image", this.uploadedFile);
        console.log(this.uploadedFile);

        this.isLoading = true;

        try {
          const response = await axios.post(
            "https://finex.4help.tn/api/v1/ocr_api",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Add this header for file upload
              },
            }
          );
          (this.isLoading = false),
            (this.uploadedFileUrl =
              this.imageUrl +
              "uploads/" +
              this.uploadedFile.name);

          console.log(response)

          this.uploadedFileText = response.data.extractedText;

          //calling NLP functions
          this.extractNumFacture(this.uploadedFileText)
          this.extracDateFacture(this.uploadedFileText)
          this.extractSupplierName(this.uploadedFileText)
          this.extractTotalTTC(this.uploadedFileText)
          this.extractCurrency(this.uploadedFileText)

          this.Splitted_Words_from_text = this.uploadedFileText.split(/\s+/);
          console.log(this.Splitted_Words_from_text);



          // alert(this.Splitted_Words_from_text[0]);


        } catch (error) {
          console.error("Error submitting form:", error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async EnregistrerProduit() {
      console.log(this.InvoiceVariables)
      axios.post("https://finex.4help.tn/api/v1/addpruchaseinvoice", this.InvoiceVariables).then(async (res) => {
        if (res.status === 200) {
          swal({
            title: 'Success',
            text: 'invoice added!',
            icon: 'success',
          });

          await this.getFactureAchat()
          this.ImportInvoice = false
          this.initInvoiceVars()

        } else if (res.status === 409) {
          swal({
            title: 'Error',
            text: 'invoice already exists!',
            icon: 'error',
          })
        }else if (res.status === 410) {
          swal({
            title: 'Warning',
            text: 'supplier not found! please add supplier first!',
            icon: 'warning',
          })
        }else if (res.status === 500) {
          swal({
            title: 'Error',
            text: 'server error!',
            icon: 'error',
          })
        }
      })
      this.showPopup = true;

      this.isPopupVisible = true;
    },

    extractCurrency(inputText) {
      const currencyRegex = /(?:devise|currency)\s*:\s*(\S+)/i;
      const match = inputText.match(currencyRegex);
      console.log(match)
      if (match) {
        this.InvoiceVariables.Currency = match[1].toUpperCase(); // Store the matched word in lowercase
      }
    },

    extracDateFacture(inputText) {
      const referenceRegex = /\b(date)\b\s*[:]?[ \/-]?(\d+)[ \/-](\d+)[ \/-](\d+)/i
      const match = inputText.match(referenceRegex);
      console.log(match)
      if (match && match[2] && match[3] && match[4]) {
        this.InvoiceVariables['Invoice Date'] = `${match[2]}-${match[3]}-${match[4]}`;
        this.InvoiceVariables['Due Date'] = `${match[2]}-${match[3]}-${match[4]}`;
      }
    },


    async extractNumFacture(inputText) {
      const referenceRegex = /FACTURE\s*(N°|#)?\s*([a-zA-Z0-9-]+)/i
      const match = inputText.match(referenceRegex);
      console.log(match)
      if (match) {
        this.InvoiceVariables["Invoice Number"] = match[2]; // Assuming the invoice number is captured at index 7
      }
    },

    extractSupplierName(text) {
      const lines = text.split('\n'); // Split text into lines
      this.InvoiceVariables['Supplier Name'] = lines[0].trim(); // Extract the first line and remove leading/trailing spaces
    },

    extractTotalTTC(text) {
      const regex = /\bTOTAL\s+TTC\s*[:]?[ \/-]?([0-9,.]+)/i;
      const match = text.match(regex);

      if (match && match[1]) {
        this.InvoiceVariables['Total all-inclusive'] = match[1].replace(/,/g, ''); // Remove commas from the matched number
      }
      console.log(this.InvoiceVariables['Total all-inclusive'])
    },



  },
  toggleSidebar() {
    this.isOpen = !this.isOpen;

    if (this.isSidebarOpen) {
      this.isSidebarOpen = false;
    } else {
      this.isSidebarOpen = true;
    }
  },
};
</script>
<style scoped>

.card {
  min-height: 100%;
  margin-bottom: 0px !important;
}

.word-container {

  margin: 10px;
  padding: 5px;
  padding-top: 25px;
  background-color: #f0f0f0;
}

.draggable-word {
  margin: 5px;
}

.draggable-word:hover {
  background: #ccc;
  border-radius: 5%;
  cursor: pointer;
}

.TableInfo {
  width: 100%;
  height: fit-content;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.file-upload-button {
  background-color: #082748;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.file-upload-button:hover {
  background-color: #0056b3;
}

.file-download-button {
  background-color: #dd4b0c;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.file-download-button:hover {
  background-color: #f8773f;
}

.uploaded-file {
  margin-top: 20px;
}

.uploaded-image {
  height: 70vh;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.uploaded-pdf {
  width: 100%;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.accueil {
  text-align: center;
  padding: 3rem;
  justify-content: center;
  align-items: center;
}



@media (max-width: 768px) {
  .accueil {
    padding: 20px;
  }


  h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
  }


}


th,
td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

tr {
  height: 50px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s;
}

@media (max-width: 768px) {
  table {
    font-size: 14px;
  }
}

.form-container {
  margin: 20px;
  margin-top: -20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 95%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}



label {
  font-weight: bold;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

input[type="text"] {
  background: #f2f2f2;
  border: none;
  height: 100%;
}

table {
  width: 60%;
  border-collapse: collapse;
  margin-top: 20px;
  height: 500px;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  transition: background-color 0.5s;
}

th {
  background-color: #f2f2f2;
}

tr.custom-height {
  min-height: 1rem;
  /* Change this value as needed */
}

td input {
  width: 100% !important;
}</style>
